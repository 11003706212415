/* eslint-disable global-require */
import { BOOK_TOP_LEVEL_CATEGORIES } from 'constants/index';

// The top-level (parent) route
const routes = {
  path: '',

  // Keep in mind, routes are evaluated in order
  children: [
    {
      path: '',
      load: () => import(/* webpackChunkName: 'home' */ './home'),
    },
    {
      path: ['/search', '/search/fulltext'],
      load: () => import(/* webpackChunkName: 'search' */ './search'),
    },
    {
      path: '/books/popularity',
      action() {
        return {
          redirect: '/books/popular',
        };
      },
    },
    {
      path: [
        '/books',
        `/books/:category(${BOOK_TOP_LEVEL_CATEGORIES.join('|')})`,
        `/books/:category(${BOOK_TOP_LEVEL_CATEGORIES.join('|')})/:viewType(list|grid)`,
        '/books/:tab',
        '/books/:tab(home)/:category(popular|az)',
        '/books/:tab/:category',
        '/books/:tab/:category/:viewType(list|grid)',
        '/books/:tab/:orderBy(az|popular|mostbooks)',
        '/books/:tab/:orderBy(az|popular|mostbooks)/:orderByDirection(asc|desc)',
      ],
      load: () => import(/* webpackChunkName: 'books' */ './books'),
    },
    {
      path: ['/book/:id/buy', '/book/:id/:title/buy'],
      load: () => import(/* webpackChunkName: 'book-purchase' */ './book/purchase'),
    },
    {
      path: ['/book/:id/_priceinfo', '/book/:id/:title/_priceinfo'],
      load: () => import(/* webpackChunkName: 'alacarte-debug-prices' */ './book/prices'),
    },
    {
      path: ['/book/:id', '/book/:id/:title'],
      load: () => import(/* webpackChunkName: 'book' */ './book'),
    },
    {
      path: [
        '/(authors|people)',
        '/(authors|people)/:orderBy(az|popular|mostbooks|recommended)',
        '/(authors|people)/:orderBy(az|popular|mostbooks|recommended)/:orderByDirection(asc|desc)',
      ],
      load: () => import(/* webpackChunkName: 'books' */ './people'),
    },
    {
      path: '/authors/popularity',
      action() {
        return {
          redirect: '/authors/popular',
        };
      },
    },
    {
      path: '/collection/:id/edit',
      load: () => import(/* webpackChunkName: 'collection-edit' */ './collection-edit'),
    },
    {
      path: [
        '/collection/:id',
        '/collection/:id/:name',
        '/collection/:id/:type(books|people)',
        '/collection/:id/:name/:type(books|people)',
        '/collection/:id/:key/:name',
        '/collection/:id/:key/:name/:type(books|people)',
      ],
      load: () => import(/* webpackChunkName: 'collection' */ './collection'),
    },
    {
      path: [
        '/collections',
        '/collections/:sortBy(az|recent|created)',
        '/collections/:tag',
        '/collections/:tag/:sortBy',
      ],
      load: () => import(/* webpackChunkName: 'collections' */ './collections'),
    },
    {
      path: ['/people/:id', '/authors/:id'],
      load: () => import(/* webpackChunkName: 'person' */ './person'),
    },
    {
      path: ['/login', '#login'],
      load: () => import(/* webpackChunkName: 'web-login' */ './login'),
    },
    {
      path: '/login/changeuser',
      load: () => import(/* webpackChunkName: 'web-login-changeuser' */ './changeuser'),
    },
    {
      path: '/signup',
      load: () => import(/* webpackChunkName: 'web-signup' */ './signup'),
    },
    {
      path: '/subscribe',
      load: () => import(/* webpackChunkName: 'subscribe' */ './subscribe'),
    },
    {
      path: '/join',
      load: () => import(/* webpackChunkName: 'join' */ './join'),
    },
    {
      path: '/premium',
      load: () => import(/* webpackChunkName: 'premium' */ './premium'),
    },
    {
      path: '/reset-password',
      load: () => import(/* webpackChunkName: 'web-reset' */ './reset'),
    },
    {
      path: ['/favourites', '/favorites'],
      load: () => import(/* webpackChunkName: 'favourites' */ './favourites'),
    },
    {
      path: '/admin-panel',
      children: [
        {
          path: '/review-images',
          load: () =>
            import(/* webpackChunkName: 'admin-panel-review-images' */ './admin-panel/review-images'),
        },
      ],
    },
    {
      path: '/user',
      children: [
        {
          path: '/subscription',
          action() {
            return {
              redirect: '/user/membership',
            };
          },
        },
        {
          path: '/membership',
          load: () => import(/* webpackChunkName: 'user-membership' */ './user/membership'),
        },
        {
          path: '/settings',
          load: () => import(/* webpackChunkName: 'user-settings' */ './user/settings'),
        },
        {
          path: '/settings/notifications',
          load: () =>
            import(/* webpackChunkName: 'user-settings-notifications' */ './user/settings/notifications'),
        },
        {
          path: '/settings/groceries',
          load: () =>
            import(/* webpackChunkName: 'user-settings-groceries' */ './user/settings/groceries'),
        },
        {
          path: '/settings/search',
          load: () =>
            import(/* webpackChunkName: 'user-settings-search' */ './user/settings/search'),
        },
        {
          path: '/settings/smartkitchen',
          load: () =>
            import(/* webpackChunkName: 'user-settings-smartkitchen' */ './user/settings/smartkitchen'),
        },
        {
          path: '/settings/appearance',
          load: () =>
            import(/* webpackChunkName: 'user-settings-appearance' */ './user/settings/appearance'),
        },
        {
          path: '/settings/units',
          load: () => import(/* webpackChunkName: 'user-settings-units' */ './user/settings/units'),
        },
        {
          path: '/membership/cancel',
          load: () =>
            import(/* webpackChunkName: 'user-membership-admin' */ './user/membership/cancel'),
        },
        {
          path: '/membership/cancel/plans',
          load: () =>
            import(/* webpackChunkName: 'user-membership-admin' */ './user/membership/cancel/plans'),
        },
        {
          path: '/membership/annual',
          load: () =>
            import(/* webpackChunkName: 'user-membership-admin' */ './user/membership/annual'),
        },
        {
          path: '/notifications',
          load: () => import(/* webpackChunkName: 'user-notifications' */ './user/notifications'),
        },
      ],
    },
    {
      path: '/auth',
      children: [
        {
          path: '/login',
          load: () => import(/* webpackChunkName: 'auth-login' */ './auth/login'),
        },
        {
          path: '/signup',
          load: () => import(/* webpackChunkName: 'auth-signup' */ './auth/signup'),
        },
        {
          path: '/reset-password',
          load: () => import(/* webpackChunkName: 'auth-reset' */ './auth/reset'),
        },
        {
          path: '/welcome',
          load: () => import(/* webpackChunkName: 'auth-welcome' */ './auth/welcome'),
        },
      ],
    },
    {
      path: '/homepage',
      children: [
        {
          path: '/collection/:id',
          load: () => import(/* webpackChunkName: 'home-collection' */ './home/collection'),
        },
      ],
    },
    {
      path: '/app',
      children: [
        {
          path: '/developer',
          load: () => import(/* webpackChunkName: 'app-developer' */ './app/developer'),
        },
        {
          path: '/settings',
          load: () => import(/* webpackChunkName: 'app-settings' */ './app/settings'),
        },
      ],
    },
    {
      path: [
        '/features',
        '/features/category/:category',
        '/features/tags/:tags',
        '/features/tag/:tags',
      ],
      load: () => import(/* webpackChunkName: 'blogs' */ './blogs'),
    },
    {
      path: '/features/:id+',
      load: () => import(/* webpackChunkName: 'blog' */ './blog'),
    },
    {
      path: '/claim/:partner/homeconnect',
      load: () => import(/* webpackChunkName: 'homeconnect-claim' */ './homeconnect-claim'),
    },
    {
      path: ['/claim', '/claim/:partner/:code', '/claim/:partner'],
      load: () => import(/* webpackChunkName: 'voucher-claim' */ './claim'),
    },
    {
      path: '/embed',
      load: () => import(/* webpackChunkName: 'embed' */ './embed'),
    },
    {
      path: '/recipe/:id/_groceries',
      load: () => import(/* webpackChunkName: 'groceries-redirect' */ './groceries/redirect'),
    },
    {
      path: ['/:type(recipe|reference|section)/:id', '/:type(recipe|reference|section)/:id/:title'],
      load: () => import(/* webpackChunkName: 'content' */ './content'),
    },
    {
      path: '/unsubscribe',
      load: () => import(/* webpackChunkName: 'unsubscribe' */ './unsubscribe'),
    },

    // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
    {
      path: '(.*)',
      load: () => import(/* webpackChunkName: 'not-found' */ './not-found'),
    },
  ],

  async action({ next }) {
    // Execute each child route until one of them return the result
    const route = await next();
    return route;
  },
};

// The error page is available by permanent url for development mode
if (__DEV__) {
  routes.children.unshift({
    path: '/error',
    action: require('./error').default,
  });
}

export default routes;
