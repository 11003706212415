/* eslint-disable jsx-a11y/anchor-has-content */

// node modules
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

// app modules: actions
import { closeSidemenu } from 'actions/sidemenu';
import { closeAvatarMenu } from 'actions/avatarMenu';

// app modules: components
import Icon from 'components/Icon';
import Image from 'components/Image';
import Link from 'components/Link';

// app modules: props
import LoginProps from 'components/Login/LoginProps';

// app modules: services
import assets from 'services/assets';

// assets
import CssLayoutUserMenu from './LayoutUserMenu.scss';
import isModerator from '../../api/helpers/isModeratorHelper';

class LayoutUserMenu extends Component {
  static propTypes = {
    user: LoginProps.isRequired,
    closeSidemenu: PropTypes.func.isRequired,
    closeAvatarMenu: PropTypes.func.isRequired,
    currentPathname: PropTypes.string.isRequired,
  };

  static items = [
    {
      url: '/user/membership',
      text: 'My membership',
    },
    {
      url: '/user/settings',
      text: 'My settings',
    },
    {
      url: 'https://join.ckbk.com/gift/',
      text: 'Gift subscriptions',
      icon: 'gift',
      external: true,
    },
    {
      url: 'https://www.facebook.com/groups/ckbkclub',
      text: '#ckbkclub',
      external: true,
    },
  ];

  constructor(props) {
    super(props);

    this.bound = {
      handleClose: this.handleClose.bind(this),
    };
  }

  componentDidMount() {
    if (!this.props.user) {
      return;
    }

    if (isModerator(this.props.user)) {
      const existingItem = LayoutUserMenu.items.find(
        item => item.url === '/admin-panel/review-images',
      );

      if (existingItem) {
        return;
      }

      LayoutUserMenu.items.push({
        url: '/admin-panel/review-images',
        text: 'New reviews with images',
      });
    }
  }

  handleClose() {
    this.props.closeSidemenu();
    this.props.closeAvatarMenu();
    return true;
  }

  handleLogoutClick = event => {
    const { currentPathname } = this.props;
    event.preventDefault();
    this.props.closeSidemenu();
    this.props.closeAvatarMenu();
    localStorage.clear();
    localStorage.setItem('last_logout', Date.now());
    window.location = `/logout?returnTo=${encodeURIComponent(currentPathname)}`;
  };

  renderItems() {
    return LayoutUserMenu.items.map(item => (
      <li className="layout-user-menu__item" key={item.url}>
        {item.external ? (
          <a
            href={item.url}
            className="layout-user-menu__link"
            onClick={this.bound.handleClose}
            target="_blank"
            rel="noopener noreferrer"
          >
            {item.text}
            {item.icon && (
              <Icon
                fill="currentColor"
                className="layout-user-menu__icon"
                name={item.icon}
                height="20"
                width="20"
              />
            )}{' '}
          </a>
        ) : (
          <Link to={item.url} className="layout-user-menu__link" onClick={this.bound.handleClose}>
            {item.text}
          </Link>
        )}
      </li>
    ));
  }

  render() {
    const { user, currentPathname } = this.props;

    let subscribeItem = null;
    if (!user.subscription) {
      subscribeItem = (
        <li className="layout-user-menu__item">
          <Link to="/join" className="layout-user-menu__link" onClick={this.bound.handleClose}>
            Try Premium Membership
          </Link>
        </li>
      );
    }
    if (user.subscription && user.subscription.expired) {
      subscribeItem = (
        <li className="layout-user-menu__item">
          <Link
            to="/user/membership"
            className="layout-user-menu__link"
            onClick={this.bound.handleClose}
          >
            Renew Premium Membership
          </Link>
        </li>
      );
    }

    return (
      <div className="layout-user-menu">
        <header className="layout-user-menu__header">
          <Image
            image={assets.avatarUrl('/login/avatar.png', {
              resize: 'fit-in',
              width: 80,
              height: 80,
            })}
            width="30"
            height="30"
            alt="Avatar"
            shape="circle"
          />
          <span className="layout-user-menu__username">{user.display_name}</span>
        </header>
        <ul>
          {subscribeItem}
          {this.renderItems()}
          <li className="layout-user-menu__item">
            <a
              href={`/logout?returnTo=${encodeURIComponent(currentPathname)}`}
              onClick={this.handleLogoutClick}
              className="layout-user-menu__link layout-user-menu__link--last"
            >
              Log out
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  currentPathname: state.router.pathname,
});

const mapDispatchToProps = {
  closeSidemenu,
  closeAvatarMenu,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(CssLayoutUserMenu)(LayoutUserMenu));
