// node modules
import React from 'react';
import { DOMParser } from 'xmldom';
import textContent from 'react-addons-text-content';
import dayjs from 'dayjs';

// This module is used in the Elastic Search reindex. When called as a script the absolute path names do not work.
// Until we resolve this we must stick to relative paths in imports.

// app modules: components
import Link from '../components/Link';

// app modules: services
import route from '../services/route';

export const longDate = date => dayjs(date).format('D MMMM YYYY');

const formatter = {
  /**
   * Returns string representation of book authors.
   * @param  {Array} authors
   * @return {Array.<ReactElement>}
   */
  authorsList: {
    toDOM: authors => {
      if (authors !== null) {
        const lastIndex = authors.length - 1;

        return authors.map((author, index) => {
          const authorName = `${author.first_name} ${author.last_name}`;

          if (index === 0) {
            return <span key={author.id}>{authorName}</span>;
          } else if (index === lastIndex) {
            return <span key={author.id}> and {authorName}</span>;
          }

          return <span key={author.id}>, {authorName}</span>;
        });
      }

      return '';
    },

    toDOMWithLinks: (authors, routeFn = route.person) => {
      if (authors !== null) {
        const lastIndex = authors.length - 1;

        return authors.map((author, index) => {
          const authorName = `${author.first_name} ${author.last_name}`;

          if (index === 0) {
            return (
              <Link to={routeFn(author.id, author.is_author)} key={author.id}>
                <span>{authorName}</span>
              </Link>
            );
          } else if (index === lastIndex) {
            return (
              <span key={author.id}>
                {' '}
                and{' '}
                <Link to={routeFn(author.id, author.is_author)}>
                  {authorName}
                </Link>
              </span>
            );
          }

          return (
            <Link to={routeFn(author.id, author.is_author)} key={author.id}>
              <span>, {authorName}</span>
            </Link>
          );
        });
      }

      return '';
    },

    /**
     * Iterates over array of authors and
     * returns a list of strings.
     * @param  {Array.<Object>|Array.<String>} authors
     * @return {String}
     */

    toString: authors => {
      if (authors) {
        const lastIndex = authors.length - 1;

        return authors
          .map((author, index) => {
            const authorName =
              typeof author === 'object' ? `${author.first_name} ${author.last_name}` : author;

            if (index === 0) {
              return authorName;
            } else if (index === lastIndex) {
              return ` and ${authorName}`;
            }

            return `, ${authorName}`;
          })
          .join('');
      }

      return '';
    },
  },

  // Used to generate a stable identifier for some content independent of the ID.
  // This can be used to recognise content in the event that an ID is changed.
  contentHash: {
    generate(components) {
      if (components && components.length) {
        return components
          .filter(component => component)
          .map(component =>
            component
              .toLowerCase()
              .replace(
                /\b(a|an|and|are|as|at|be|but|by|for|if|in|into|is|it|no|not|of|on|or|such|that|the|their|then|there|these|they|this|to|was|will|with)\b/g,
                '',
              )
              .replace(/[^\w\d]/g, ''),
          )
          .join('-');
      }
      return null;
    },
  },

  xml: {
    /**
     * Parse XML string and returns HTMLElement.
     * @param  {String} raw
     * @return {HTMLElement}
     */
    toHTML: (raw, wrap = true) => {
      const parser = new DOMParser();
      // We're wrapping `raw` with a `<div>` in case of multiple children.
      // <p>...</p><p>...</p> - will return parser error.
      // <div><p>...</p><p>...</p></div> - will parse without errors.
      const XML = parser.parseFromString(wrap ? `<div>${raw}</div>` : raw, 'text/xml');
      return XML.documentElement;
    },

    setHTML: (element, raw) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(`<div>${raw}</div>`, 'text/html');
      const ownNode = element.ownerDocument.importNode(doc.documentElement, true);

      while (element.firstChild) {
        element.removeChild(element.firstChild);
      }
      while (ownNode.firstChild) {
        element.appendChild(ownNode.firstChild);
      }
    },

    /**
     * Removes all elements with provided tag.
     * @param  {Object} XML
     * @param  {String} tagName
     */
    removeAll(XML, tagName) {
      Array.from(XML.getElementsByTagName(tagName)).forEach(node => {
        node.parentNode.removeChild(node);
      });
    },

    internalizeLinks(XML) {
      // internalLink tags are anchors, but were not sent as <a> tags because a previous version of the
      // client would assume all non-recipe links should have '/section' prepended to them.
      // To avoid serving broken links to such clients, the parser at ingest time will insert these
      // links as <internalLink> and we just need to rename them to <a>.
      // Our implementation of xmldom doesn't support renameNode so we have to create a new node and copy everything over.
      Array.from(XML.getElementsByTagName('internalLink')).forEach(innerLink => {
        const anchor = XML.ownerDocument.createElement('a');

        Array.from(innerLink.attributes).forEach(attribute => {
          anchor.setAttributeNode(attribute.cloneNode());
        });

        do {
          anchor.appendChild(innerLink.firstChild);
        } while (innerLink.firstChild);

        innerLink.parentNode.replaceChild(anchor, innerLink);
      });
      // We need to add a click handler to all links to ensure they are navigated using client side routing.
      Array.from(XML.getElementsByTagName('a')).forEach(anchor => {
        const hrefRaw = anchor.getAttribute('href');
        const linkType = anchor.getAttribute('data-link-type');

        if (hrefRaw.indexOf('http:') === 0 || hrefRaw.indexOf('https:') === 0) {
          anchor.setAttribute('href', hrefRaw);
          anchor.setAttribute('target', '_blank');
        } else if (!linkType) {
          const contentUrl = route.content(hrefRaw);
          anchor.setAttribute('href', contentUrl);
          if (contentUrl.startsWith('/images/')) {
            // TODO display in a lightbox? For now we'll open in a new window
            anchor.setAttribute('target', '_blank');
          } else {
            anchor.setAttribute('onclick', `ckbk.route(event, "${route.content(hrefRaw)}");`);
          }
        } else if (!linkType === 'image') {
          anchor.setAttribute('onclick', `ckbk.route(event, "${hrefRaw}");`);
        }
        anchor.setAttribute('class', 'link link--energized');
      });
    },
  },
  meta: {
    getCopyrightText(book) {
      // The recipe_copyright_line is always used if set
      if (book.recipe_copyright_line) {
        return book.recipe_copyright_line;
      }
      if (!book.licensor) {
        return null;
      }
      // Special values - if any of the below appears in licensor field do not display any copyright
      if (book.licensor.includes('Out of Copyright') || book.licensor.includes('Orphan')) {
        return null;
      }
      // If the book is licensed from an author print the copyright with author's name
      if (book.licensor.startsWith('Author')) {
        const regex = /\(([^)]+)\)/;
        const trimAuthor = string => regex.exec(string)[1];
        try {
          return `© ${book.publication_date} ${trimAuthor(book.licensor)}. All rights reserved.`;
        } catch (err) {
          // fallthrough
        }
      }
      // If the book is licensed from a publisher
      return `© ${book.publication_date} All rights reserved. Published by ${book.licensor}.`;
    },
  },

  truncation: {
    getElementLength(children) {
      const isChildrenArray = Array.isArray(children);
      let intro = null;

      if (children && !isChildrenArray) {
        intro = children;
      } else if (children && isChildrenArray) {
        intro = children.map(child => textContent(child.props.raw)).join();
      } else {
        return 0;
      }
      return intro.length;
    },
  },

  localPrice(value, currency) {
    const numericPrice = value ? parseFloat(value) : 0;

    try {
      if (numericPrice) {
        return numericPrice.toLocaleString(
          undefined,
          { style: 'currency', currency },
        );
      }
    } catch (error) {
      // Ignore
    }
    // The value is probably already formatted
    return value;
  },

  longDate,

  removeSmartCharacters(value) {
    // iOS 11 replaces quotes with curly quotes and dashes with EmDashes. Sometimes we don't want this.
    // Single quotes
    let clean = value.replace(/[\u2018\u2019\u201B\u2032\u2035]/g, "'");
    // Double quotes
    clean = clean.replace(/[\u201C\u201F\u201D\u2033\u2036]/g, '"');
    // Dashes
    clean = clean.replace(/[\u2013]/g, '-');
    // Double dashes
    clean = clean.replace(/[\u2014]/g, '--');
    return clean;
  },

  base64: {
    encode(value) {
      if (typeof window !== 'undefined' && window.btoa) {
        // Browser
        return window.btoa(value);
      } else if (Buffer) {
        // Server
        return Buffer.from(value).toString('base64');
      }
      return null;
    },
  },
};

export const formatBrandName = brand => {
  switch (brand.toLowerCase()) {
    case 'neff':
      return 'NEFF';
    default:
      return brand.charAt(0).toUpperCase() + brand.slice(1);
  }
};

export default formatter;
