/* eslint-disable camelcase */
import btoa from 'btoa';
import { getStore } from '../store/configureStore';

const getCdnConfig = () =>
  getStore() !== null ? getStore().getState().config.cdn.contentImage : { baseUrl: '' };

const buildImageUrl = (name, type, options = {}) => {
  // See https://docs.aws.amazon.com/solutions/latest/serverless-image-handler/deployment.html#step2
  // and also https://sharp.pixelplumbing.com/en/stable/
  const cdn = getCdnConfig();
  const imageRequest = {
    key: name.startsWith('/') ? name.substring(1) : `${type}/${name}`,
    edits: {},
  };

  if ((options.width || options.height) && imageRequest.key !== 'covers/default.png') {
    imageRequest.edits.resize = { withoutEnlargement: true };

    imageRequest.edits.resize.width = options.width;
    imageRequest.edits.resize.height = options.height;
    if (options.width && options.height) {
      if (options.resize === 'fit-in') {
        imageRequest.edits.resize.fit = 'inside';
      } else if (options.resize === 'contain') {
        imageRequest.edits.resize.fit = 'contain';
        imageRequest.edits.resize.background = options.background || '#8e8d8d';
      } else {
        imageRequest.edits.resize.fit = 'cover';
        imageRequest.edits.resize.position = options.position || 'entropy';
      }
    }
  }
  if (options.webp === false) {
    imageRequest.edits.toFormat = 'jpeg';
  }
  if (options.original === true) {
    imageRequest.edits = { toFormat: 'jpeg' };
  }

  return `${cdn.baseUrl}/${btoa(JSON.stringify(imageRequest))}`;
};

// This is needed so we can build URLs for our old image service.
// Google metadata must contain these to avoid breaking the link between a Recipe and
// the Image as it causes the Recipe to disappear.
const buildOldImageUrl = (name, type, options = {}) => {
  // See https://docs.aws.amazon.com/solutions/latest/serverless-image-handler/deployment.html#step2
  // and also https://sharp.pixelplumbing.com/en/stable/
  const cdn = getCdnConfig();
  const nameWithoutPrefix = name.replace(/\/images\/v[0-9]+\//, '');
  const imageRequest = {
    bucket: cdn.bucket,
    key: nameWithoutPrefix.startsWith('/')
      ? nameWithoutPrefix.substring(1)
      : `${type}/${nameWithoutPrefix}`,
    edits: {},
  };

  if (options.width || options.height) {
    imageRequest.edits.resize = { withoutEnlargement: true };

    imageRequest.edits.resize.width = options.width;
    imageRequest.edits.resize.height = options.height;
    if (options.width && options.height) {
      if (options.resize === 'fit-in' || options.resize === 'contain') {
        imageRequest.edits.resize.fit = 'inside';
      } else {
        imageRequest.edits.resize.fit = 'cover';
        imageRequest.edits.resize.position = options.position || 'entropy';
      }
    }
  }
  if (options.quality !== 'original') {
    imageRequest.edits.jpeg = { quality: options.quality || 90, progressive: true };
  } else {
    imageRequest.edits.jpeg = { progressive: true };
  }
  if (options.webp === false) {
    imageRequest.edits.toFormat = 'jpeg';
  }
  if (options.original === true) {
    imageRequest.edits = { toFormat: 'jpeg' };
  }
  return `https://d29hmqxeker05q.cloudfront.net/${btoa(JSON.stringify(imageRequest))}`;
};

export const getOldRecipeSource = (image, options) => {
  switch (image.type) {
    // eslint-disable-next-line no-case-declarations
    case 'instagram':
      const { key } = JSON.parse(
        Buffer.from(new URL(image.src).pathname.substring(1), 'base64').toString('ascii'),
      );
      return `https://d29hmqxeker05q.cloudfront.net/${Buffer.from(
        JSON.stringify({
          bucket: 'images.ckbk.com',
          key,
        }),
      ).toString('base64')}`;

    case 'primary':
    case 'photo':
      return buildOldImageUrl(image.src, 'images', options);

    default:
      return null;
  }
};

export default {
  /**
   * Creates a URL to the HOMEPAGE hero image.
   * @param  {String} name
   * @param  {Object} options
   * @return {String}
   */
  heroUrl(name, options) {
    // The parsing according to the / is introduced as a fix to the image quality issue we're facing, so now we are getting
    // the whole S3 key as the image name, for that, we check if the name has the slash, if it does means it's an s3 key,
    // so we destruct it into image type and name
    const type = name.includes('/') ? name.split('/')[0] : 'hero';
    const imgName = name.includes('/') ? name.split('/')[1] : `${name || 'default'}.jpg`;
    return buildImageUrl(imgName, type, options);
  },

  /**
   * Creates a URL to the ingredient image.
   * @param  {String} name
   * @param  {Object} options
   * @return {String}
   */
  ingredientsUrl(name, options) {
    return buildImageUrl(`${name || 'default'}.jpg`, 'ingredients', options);
  },

  /**
   * Creates a URL to the collection image.
   * @param  {String} name
   * @param  {Object} options
   * @return {String}
   */
  collectionUrl(name, options) {
    const nameWithoutExtension = name.replace(/\.jpg$/i, '');
    return buildImageUrl(`${nameWithoutExtension}.jpg`, 'collections', options);
  },

  /**
   * Creates a URL to the person avatar.
   * @param  {String} name
   * @param  {Object} options
   * @return {String}
   */
  avatarUrl(name, options) {
    if (name && (name.startsWith('https://') || name === '/login/avatar.png')) {
      return name;
    }
    return buildImageUrl(name ? `${name.trim()}.jpg` : 'default.png', 'avatars', options);
  },

  /**
   * Creates a URL to the book cover.
   * @param  {String} name
   * @param  {Object} options
   * @return {String}
   */
  coverUrl(name, options) {
    return buildImageUrl(name ? `${name.trim()}.jpg` : 'default.png', 'covers', options);
  },

  awardUrl(name, options) {
    return buildImageUrl(`${name.trim()}.jpg`, 'awards', options);
  },

  instagramThumbnailUrl(name, options) {
    return buildImageUrl(name, 'instagram', options);
  },

  adUrl(name, options) {
    return buildImageUrl(`${name.trim()}`, 'ads', options);
  },

  backdropUrl(name, options) {
    return buildImageUrl(`${name.trim()}`, 'backdrops', options);
  },

  partnerUrl(name, options) {
    return buildImageUrl(`${name.trim()}`, `partners/${options.partner}`, options);
  },

  srcSet(image, options, getUrlFn) {
    return options
      .filter(option => !image.width || image.width >= option.w || option.density)
      .map(option => {
        const spec = option.density ? `${option.density}x` : `${option.w}w`;
        return `${getUrlFn(image, { ...option, width: option.w, height: option.h })} ${spec}`;
      })
      .join(',');
  },

  recipe: {
    /**
     * Creates a URL to the recipe image.
     * @param  {String} name
     * @param  {Object} options
     * @return {String}
     */
    url(name, options) {
      return buildImageUrl(name, 'images', options);
    },

    /**
     * @param  {Object} options
     * @param  {Boolean} options.image_permission
     * @param  {String} options.instagram
     * @param  {Array} options.graphics
     * @return {Object}
     */
    getImage(options) {
      const {
        image_permission,
        instagram, // deprecated - use instagramThumbnailUrl
        instagramThumbnailUrl,
        instagramPostUrl,
        instagramAuthor,
        graphics,
        preferredVersion,
      } = options;
      const photos = graphics
        .filter(
          graphic =>
            graphic.graphictype &&
            graphic.graphictype.startsWith('photo') &&
            graphic.type !== 'graphic',
        )
        .sort((a, b) => {
          const preferred = preferredVersion ? `photo_${preferredVersion}` : 'photo';
          if (a.graphictype === b.graphictype) {
            return 0;
          }
          if (b.graphictype === preferred) {
            return 1;
          }
          return -1;
        });

      const primary = photos.find(graphic => graphic.type === 'recipegraphic-primary');
      const instagramImage = instagramThumbnailUrl || instagram;

      if (image_permission === true) {
        if (typeof primary !== 'undefined') {
          return Object.assign({}, primary, {
            type: 'primary',
          });
        } else if (photos.length > 0 && !instagramImage) {
          return Object.assign({}, photos[0], {
            type: 'photo',
          });
        }
      }

      if (instagramImage) {
        return {
          type: 'instagram',
          src: instagramImage,
          instagramAuthor,
          instagramPostUrl,
          height: 640,
          width: 640,
        };
      }

      return {
        type: 'unknown',
        src: null,
      };
    },

    /**
     * @param  {Object} image
     * @param  {String} image.type
     * @param  {String} image.src
     * @param  {Number} image.height
     * @param  {Number} image.width
     * @return {String}
     */
    getSource(image, options) {
      switch (image.type) {
        // eslint-disable-next-line no-case-declarations
        case 'instagram':
          const cdn = getCdnConfig();
          return `${cdn.baseUrl}${new URL(image.src).pathname.replace(/^\/images\/v[0-9]+/, '')}`;

        case 'primary':
        case 'photo':
          return buildImageUrl(image.src, 'images', options);

        default:
          return null;
      }
    },
  },
};
