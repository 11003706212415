import Cookies from 'js-cookie';

// From https://stackoverflow.com/a/21742107/2760163
export function getOperatingSystem(userAgent) {
  if (userAgent) {
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'iOS';
    }
  }

  return null;
}

export function isIosApp() {
  return typeof window !== 'undefined' && window.cordova && window.cordova.platformId === 'ios';
}

export function isAndroidApp() {
  return typeof window !== 'undefined' && window.cordova && window.cordova.platformId === 'android';
}

export function isApp() {
  return typeof window !== 'undefined' && window.cordova;
}

export function isMac() {
  return (
    typeof window !== 'undefined' &&
    window.navigator !== 'undefined' &&
    /Mac|iPod|iPhone|iPad/.test(window.navigator.platform)
  );
}

export function checkExperimentMembership(id, percentage) {
  const cookieName = `ckbk-experiment-${id}`;
  const cookieValue = Cookies.get(cookieName);
  let selected = false;

  if (!cookieValue) {
    const rand = Math.random() * 100;
    if (percentage > rand) {
      selected = true;
    }
    Cookies.set(cookieName, String(selected), { SameSite: 'Lax' });
  } else if (cookieValue === 'true') {
    selected = true;
  }

  return selected;
}

// This might not be 100% accurate...
// see https://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript
export function isTouchDevice() {
  return (
    typeof window !== 'undefined' &&
    ('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0)
  );
}

export default {
  getOperatingSystem,
  isIosApp,
  isAndroidApp,
  isApp,
  isMac,
};
