/* eslint-disable global-require */

// node modules
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

// app modules: components
import Advert from 'components/Advert';
import Banner from 'components/Banner';
import Footer from 'components/Home/Footer';
import LayoutHeader from 'components/LayoutHeader';
import Intercom from 'components/Intercom';
import Satismeter from 'components/Satismeter';
import Sidebar from 'components/Sidebar';
import Profitwell from 'components/Profitwell';

// app modules: props
import LayoutProps from 'components/Layout/LayoutProps';

// constants
import { BREAKPOINTS } from 'constants/index';

// assets
import CssFonts from '../../fonts.scss';
import CssScaffolding from '../scaffolding.scss';
import CssWebLayout from './WebLayout.scss';
import CssLink from '../link.scss';
import CssRcMenu from '../rc-menu.scss';
import CssRcSlider from '../rc-slider.scss';
import CssRcTooltip from '../rc-tooltip.scss';
import CssRcTooltipLight from '../rc-tooltip-light.scss';
import CssRcTooltipPlain from '../rc-tooltip-plain.scss';
import CssSlideout from '../slideout.scss';
import CssToastify from '../toastify/main.scss';
import CssVideoWrapper from '../video-wrapper.scss';

class WebLayout extends Component {
  static propTypes = {
    type: PropTypes.oneOf(['normal', 'wide', 'full']),
    children: PropTypes.node,
    dimensions: LayoutProps.dimensions.isRequired,
    sidemenu: PropTypes.shape({
      isOpen: PropTypes.bool,
    }).isRequired,
    meteredAccess: PropTypes.shape({
      visible: PropTypes.bool,
      extended: PropTypes.bool,
    }).isRequired,
    onSetPanelRef: PropTypes.func,
    chrome: PropTypes.bool,
    withLeaderboardAd: PropTypes.bool,
    withFooterLeaderboardAd: PropTypes.bool,
    withFooter: PropTypes.bool,
    enableSidebarAd: PropTypes.bool,
    overlayPromoBanner: PropTypes.bool,
    withPromoBanner: PropTypes.bool,
    isCorporate: PropTypes.bool,
  };

  static defaultProps = {
    children: '',
    type: 'normal',
    chrome: true,
    onSetPanelRef: () => {},
    withLeaderboardAd: true,
    withFooterLeaderboardAd: true,
    enableSidebarAd: false,
    withFooter: true,
    overlayPromoBanner: false,
    withPromoBanner: true,
    isCorporate: false,
  };

  constructor(props) {
    super(props);

    this.slideout = null;

    this.element = {
      layout: {
        menu: null,
        panel: null,
        header: null,
      },
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      this.element.layout.header = document.querySelector('.web-layout__header');
      this.initSlideout();
    }
  }

  componentDidUpdate(prevProps) {
    const { dimensions, sidemenu, chrome } = this.props;

    if (dimensions.body !== prevProps.dimensions.body) {
      this.updateSlideoutPadding();
    }

    if (chrome) {
      if (sidemenu.isOpen) {
        this.slideout.open();
      } else {
        this.slideout.close();
      }
    }
  }

  initSlideout() {
    const { chrome } = this.props;
    if (!chrome) {
      return;
    }
    const Slideout = require('slideout');
    const { menu, panel, header } = this.element.layout;

    this.slideout = new Slideout({
      panel,
      menu,
      padding: 250,
      tolerance: 70,
      touch: false,
    });

    this.slideout
      .on('beforeopen', () => {
        panel.classList.add('web-layout__panel--before-open'); // adds border-left
        header.classList.add('web-layout__header--before-open'); // triggers animation
      })
      .on('beforeclose', () => {
        header.classList.remove('web-layout__header--before-open'); // triggers animation
      })
      .on('close', () => {
        panel.classList.remove('web-layout__panel--before-open'); // remove border-left
      });
  }

  updateSlideoutPadding() {
    const { dimensions, chrome } = this.props;
    if (!chrome) {
      return;
    }

    if (dimensions.body <= BREAKPOINTS.PHONE_HORIZONTAL) {
      this.slideout._padding = 200;
      this.slideout._translateTo = 200;
    } else {
      this.slideout._padding = 250;
      this.slideout._translateTo = 250;
    }
  }

  render() {
    const {
      type,
      meteredAccess,
      chrome,
      onSetPanelRef,
      overlayPromoBanner,
      sidemenu,
      withLeaderboardAd,
      withFooterLeaderboardAd,
      enableSidebarAd,
      withFooter,
      withPromoBanner,
      isCorporate,
    } = this.props;
    const layoutClassName = classNames(
      'layout',
      'web-layout',
      `web-layout--${type}`,
      `layout--${type}`,
    );
    const layoutPanelClassName = classNames(
      'web-layout__panel',
      'slideout-panel',
      'slideout-panel-left',
      {
        'web-layout__panel--metered-access': meteredAccess.visible && !meteredAccess.extended,
        'web-layout__panel--metered-access-extended':
          meteredAccess.visible && meteredAccess.extended,
      },
    );
    const canShowBanner = chrome && withPromoBanner && !isCorporate;

    return (
      <div className={layoutClassName}>
        {chrome ? (
          <Fragment>
            <LayoutHeader className="web-layout__header" />{' '}
            <div
              className="web-layout__sidebar slideout-menu slideout-menu-left"
              ref={_menu => {
                this.element.layout.menu = _menu;
              }}
              hidden={!sidemenu.isOpen}
            >
              <Sidebar />
            </div>
          </Fragment>
        ) : null}

        <div
          className={layoutPanelClassName}
          ref={_panel => {
            this.element.layout.panel = _panel;
            onSetPanelRef(_panel);
          }}
        >
          <div className="web-layout__body-wrapper">
            {canShowBanner ? <Banner overlay={overlayPromoBanner} /> : null}
            {chrome && withLeaderboardAd ? (
              <Advert
                className="margin-bottom-10 margin-top-20"
                slotName="leaderboard-header"
                format="leaderboard"
                fullWidthResponsive={false}
                ezoicPlaceholderId={101}
              />
            ) : null}
            <div
              className={classNames('web-layout__body', {
                'web-layout__body--with-sidebar-ad': enableSidebarAd,
              })}
            >
              {this.props.children}
            </div>
          </div>
          {chrome && withFooter ? <Footer withLeaderboardAd={withFooterLeaderboardAd} /> : null}
        </div>

        <Intercom />
        <Profitwell />
        <ReactTooltip html effect="solid" type="dark" uuid="__react_component_tooltip" />
        {chrome ? <Satismeter /> : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dimensions: state.dimensions,
  sidemenu: state.sidemenu,
  meteredAccess: state.meteredAccess,
  isCorporate: state.user.geolocation.corporate,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withStyles(
    CssFonts,
    CssScaffolding,
    CssWebLayout,
    CssLink,
    CssRcMenu,
    CssRcSlider,
    CssRcTooltip,
    CssRcTooltipLight,
    CssRcTooltipPlain,
    CssSlideout,
    CssToastify,
    CssVideoWrapper,
  )(WebLayout),
);
